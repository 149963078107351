import React, { useState } from 'react';

import Loading from './Loading';

const AirtableIframe = ({ src }) => {
  const iframeStyle = {
    background: 'transparent',
  };

  const [ loading, setLoading ] = useState(true);

  const hideLoading = () => {
    setLoading(false);
  };

  return (
    <>
      { loading && <Loading /> }
      <iframe
        title="add KAUST entry"
        onLoad={ hideLoading }
        className="airtable-embed airtable-dynamic-height"
        src={ src }
        frameBorder="0"
        width="100%"
        height="2466"
        style={ iframeStyle }
      />
    </>
  );
};

export default AirtableIframe;
