import React from 'react';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

import AirtableIframe from '../components/AirtableIframe';

const SubmitPage = () => (
  <Layout>
    <SEO
      title="Submit Post"
    />
    <AirtableIframe src="https://airtable.com/embed/shrCavqcaKiyEZyHP?backgroundColor=blue" />
  </Layout>
);

export default SubmitPage;
